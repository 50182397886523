<template>
  <main v-loading="loading" class="rebate-tab">
    <template v-if="!showPage">
      <p>尚未註冊</p>
    </template>
    <template v-if="showPage">
      <WalletStatistic :memberId="memberId" :loading="loading" />
      <UseRebateBlock :memberId="memberId" :walletBalance="rebateWalletBalance" @refresh="refresh" />
      <SectionTitle title="回饋金使用紀錄" hideBtn />
      <BaseTable v-loading="loading" :data="tableData">
        <BaseElTableColumn prop="createdAt" label="建立時間" align="center">
          <template slot-scope="scope">{{ dateFormat(scope.row.createdAt) }}</template>
        </BaseElTableColumn>
        <BaseElTableColumn prop="type" label="形式" align="center">
          <template slot-scope="scope">
            {{ get(originConfig, `${scope.row.origin}.name`) }}
          </template>
        </BaseElTableColumn>
        <BaseElTableColumn prop="amount" label="金額" align="center">
          <template slot-scope="scope">
            <p :class="{'text-danger': scope.row.amount < 0}">
              <span>{{ scope.row.amount > 0 ? '+' : '' }}</span>
              <span>{{ scope.row.amount }}</span>
            </p>
          </template>
        </BaseElTableColumn>
        <BaseElTableColumn v-if="false" prop="Branch" label="操作門市" align="center">
          <template slot-scope="scope">
            {{ scope.row.Branch?.name || "-" }}
          </template>
        </BaseElTableColumn>
        <BaseElTableColumn prop="originMessage" label="備註" align="center">
          <template slot-scope="scope">{{ scope.row.originMessage || '-' }}</template>
        </BaseElTableColumn>
      </BaseTable>
      <Pagination
        class="pagination"
        :curPage.sync="tableOptions.page"
        :pageLimit="tableOptions.pageLimit"
        :total="tableOptions.dataCount"
        @pageChange="pageChange"
      />
    </template>
  </main>
</template>

<script setup>
import { useTable } from '@/use/table'
import { FindShopRebate, FindMemberRebateWallet, FindMemberRebateWalletRecord, FindMemberRebateWalletRecordCount } from '@/api/rebate'
import WalletStatistic from './components/WalletStatistic.vue'
import { originConfig } from '@/config/rebate'
import UseRebateBlock from './components/UseRebateBlock.vue'
import store from '@/store'
import { computed, ref, onMounted } from 'vue'
import { get } from 'lodash'
import { usePermissions } from '@/use/permissions'

const props = defineProps({
  memberId: {
    type: String,
  },
  rebateWalletBalance: {
    type: Number,
  },
})
const emit = defineEmits(['updateWallet'])
const { pageStartIndex, dateFormat, tableOptions, tableData } = useTable()
const loading = ref(false)
const showPage = ref(false)
// const walletBalance = ref(0)
const shopId = computed(() => store.getters.shop)
const { checkAction } = usePermissions()

const useStorePermission = computed(() => {
  return checkAction('admin.branch.adminUseStore')
})

// const findMemberRebateWallet = async () => {
//   const [res, err] = await FindMemberRebateWallet({ shopId: shopId.value, memberId: props.memberId })
//   if (err) return (window.$message.error(err))
//   walletBalance.value = res.balance
// }

const findMemberRebateWalletRecord = async () => {
  const [res, err] = await FindMemberRebateWalletRecord({
    shopId: shopId.value,
    memberId: props.memberId,
    start: pageStartIndex.value,
    limit: tableOptions.pageLimit,
  })
  if (err) return (window.$message.error(err))
  tableData.value = res
}

const findMemberRebateWalletRecordCount = async () => {
  const [res, err] = await FindMemberRebateWalletRecordCount({ shopId: shopId.value, memberId: props.memberId })
  if (err) return (window.$message.error(err))
  tableOptions.dataCount = res.count
}

const pageChange = async () => {
  await refresh()
}

const refresh = async () => {
  loading.value = true
  await findShopRebate()
  if (!showPage.value) return
  await Promise.all([
    // findMemberRebateWallet(),
    findMemberRebateWalletRecord(),
    findMemberRebateWalletRecordCount(),
  ])
  emit('updateWallet')
  loading.value = false
}

const findShopRebate = async () => {
  const [res, err] = await FindShopRebate({ shopId: shopId.value })
  if (err) return (window.$message.error(err))
  if (res.id) showPage.value = true
  else loading.value = false
}

onMounted(async () => {
  await refresh()
})
</script>

<style scoped lang="postcss">

</style>
