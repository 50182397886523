<template>
  <div v-loading="loading" class="use-rebate-block">
    <BaseElForm
      ref="formRef"
      label-position="left"
      label-width="100px"
      :model="formData"
      :rules="formRules"
    >
      <BaseElFormItem label="操作" prop="type">
        <BaseElRadioGroup v-model="formData.type">
          <BaseElRadio label="use">扣除</BaseElRadio>
          <BaseElRadio label="add">贈送</BaseElRadio>
        </BaseElRadioGroup>
      </BaseElFormItem>
      <BaseElFormItem label="點數" prop="amount">
        <BaseElInput v-model="formData.amount" />
      </BaseElFormItem>
      <BaseElFormItem label="備註" prop="commnet">
        <BaseElInput v-model="formData.comment" />
      </BaseElFormItem>
      <BaseElFormItem>
        <div>
          <BaseElButton @click="reset">取消</BaseElButton>
          <BaseElButton type="primary" @click="handleRebateWallet">儲存</BaseElButton>
        </div>
      </BaseElFormItem>
    </BaseElForm>
  </div>
</template>

<script setup>
import { reactive, ref, computed } from 'vue'
import { noEmptyRules, rangeRules } from '@/validation'
import { AddPointWalletRecord, UsePointWalletRecord } from '@/api/shopPoint'
import store from '@/store'
import formUtils from '@/utils/form'
import { debounce } from 'lodash'

const props = defineProps({
  memberId: {
    type: String,
  },
  pointBalance: {
    type: [String, Number],
    default: 0,
  },
})
const emit = defineEmits(['refresh'])

const loading = ref(false)
const formRef = ref(null)
const shopId = computed(() => store.getters.shop)
const formData = reactive({
  type: null,
  amount: null,
  comment: null,
})

const formRules = computed(() => {
  const rules = {
    type: [noEmptyRules()],
    amount: [noEmptyRules()],
  }
  if (formData.type === 'use') {
    rules.amount.push(rangeRules(1, props.pointBalance))
  }
  return rules
})

const reset = () => {
  formData.type = null
  formData.amount = null
  formData.comment = null
  setTimeout(() => {
    formRef.value.clearValidate()
  }, 150)
}

const handleRebateWallet = debounce(async () => {
  if (loading.value) return
  loading.value = true
  if (!(await formUtils.checkForm(formRef.value))) {
    loading.value = false
    return
  }
  const type = formData.type
  const methods = {
    add: AddPointWalletRecord,
    use: UsePointWalletRecord,
  }
  const [, err] = await methods[type]({
    shopId: shopId.value,
    memberId: props.memberId,
    originMessage: formData.comment || undefined,
    addAmount: type === 'add' ? formData.amount : undefined,
    useAmount: type === 'use' ? formData.amount : undefined,
  })
  loading.value = false
  if (err) return (window.$message.error(err))
  emit('refresh')
  reset()
}, 300, { leading: false, trailing: true })
</script>

<style scoped lang="postcss">

</style>
